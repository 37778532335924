<template>
  <!-- 
    画面: オプション在庫調整
    用途: オプション在庫を調整する
   -->
  <div>

    <CRow>
      <CCol>
        <CCard>

          <CCardHeader>
            {{ $t("optionInventoryEditPage.pageTitle") }}
          </CCardHeader>

          <CCardBody>
            <CForm class="submit_form">

              <!-- #region Body header -->
              <CRow>
                <CCol
                  class="label"
                  md="2"
                ><strong>表示期間</strong></CCol>
                <CCol md="2">
                  <DatePicker
                    v-model="period.startDate"
                    :popover="{ visibility: 'click' }"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="border px-2 py-1 rounded"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </DatePicker>
                </CCol>
                ～
                <CCol md="2">
                  <DatePicker
                    v-model="period.endDate"
                    :popover="{ visibility: 'click' }"
                  >
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="border px-2 py-1 rounded"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </DatePicker>
                </CCol>
              </CRow>

              <CRow class="my-2">
                <!-- #region 一括在庫設定ボタン -->
                <CCol>
                  <CButton
                    class="px-3 mt-1 bg-white text-dark"
                    color="dark"
                    ariant="outline"
                    square
                    size="sm"
                    @click="onBulkChangeModal()"
                  ><i class="icon cil-pencil mr-1"></i>{{ $t("optionInventoryEditPage.bulkChangeInventory") }}</CButton>
                </CCol>
                <!-- #endregion 一括在庫設定ボタン -->
                <!-- #region 変更内容リセットボタン -->
                <CCol class="text-center">
                  <CButton
                    class="px-3 mt-1 bg-white text-dark"
                    color="dark"
                    ariant="outline"
                    square
                    size="sm"
                    @click="onResetChangesClicked()"
                  ><i class="icon cil-history mr-1"></i>{{ $t("optionInventoryEditPage.resetChanges") }}</CButton>
                </CCol>
                <!-- #endregion 変更内容リセットボタン -->
                <!-- #region 更新ボタン -->
                <CCol>
                  <CButton
                    class="px-3 mt-1 float-right"
                    color="success"
                    square
                    size="sm"
                    @click="onSaveClicked()"
                    :disabled="$v.$invalid ? 'disabled' : undefined"
                  ><i class="icon cil-save mr-1"></i>{{ $t("common.update") }}</CButton>
                </CCol>
                <!-- #endregion 更新ボタン -->
              </CRow>
              <!-- #endregion Body header -->

              <!-- #region Alert -->
              <CRow>
                <CCol>
                  <CAlert
                    color="danger"
                    v-if="$v.$invalid"
                  >
                    <ul>
                      <template v-for="(x, i) in $v.tableItems.$each.$iter">
                        <template v-if="x.$invalid">
                          {{ tableItems[i].name }}
                        </template>
                        <template v-for="(y, j) in x.inventories.$each.$iter">
                          <li
                            :key="j"
                            v-if="y.$invalid"
                          >
                            ・{{ tableItems[i].inventories[j].date }}
                            <template v-if="y.provisionNumber.$invalid">
                              提供数不正
                            </template>
                            <template v-if="y.remainNumber.$invalid">
                              残個数不正
                            </template>
                            <template v-if="y.excessStandardInventoryNumber.$invalid">
                              基準在庫超過
                            </template>
                          </li>
                        </template>
                      </template>
                    </ul>
                  </CAlert>
                </CCol>
              </CRow>
              <!-- #endregion Alert -->

              <!-- #region Table -->
              <CRow>
                <CCol>
                  <template v-for="tableItem of tableItems">

                    <!-- #region Room summary -->
                    <div
                      class="mb-1 d-block"
                      :key="tableItem.id"
                    >
                      <br />
                      <strong>{{ $t("optionInventoryEditPage.optionName") }}</strong>: {{ tableItem.name }}
                      /
                      <strong>{{ $t("optionInventoryEditPage.standardInventory") }}</strong>: {{ tableItem.standardInventory }}
                    </div>
                    <!-- #region Room summary -->

                    <!-- #region Inventory -->
                    <div
                      class="price-list-date"
                      :key="tableItem.code"
                    >
                      <table class="edge-type-table table table-sm table-striped table-bordered m-0">
                        <tbody>

                          <tr>
                            <th class="bg-light text-dark">{{ $t("optionInventoryEditPage.date")}}</th>
                            <template v-for="item in tableItem.inventories">
                              <td :key="item.date">
                                {{ item.date | day }}
                              </td>
                            </template>
                          </tr>

                          <tr>
                            <th class="bg-light text-dark">{{ $t("optionInventoryEditPage.provisionNumber")}}</th>
                            <template v-for="item in tableItem.inventories">
                              <td :key="item.date">
                                <CInput
                                  class="w-6"
                                  size="sm"
                                  v-model.number="item.provisionNumber"
                                  type="number"
                                  @update:value="onProvisionNumberInput(tableItem, item)"
                                />
                              </td>
                            </template>
                          </tr>
                          <tr>
                            <th class="bg-light text-dark">{{ $t("optionInventoryEditPage.reservedNumber")}}</th>
                            <template v-for="item in tableItem.inventories">
                              <td :key="item.date">
                                <CInput
                                  class="w-6"
                                  size="sm"
                                  v-model.number="item.reservedNumber"
                                  type="number"
                                  disabled
                                />
                              </td>
                            </template>
                          </tr>
                          <tr>
                            <th class="bg-light text-dark">{{ $t("optionInventoryEditPage.remainNumber")}}</th>
                            <template v-for="item in tableItem.inventories">
                              <td :key="item.date">
                                <CInput
                                  class="w-6"
                                  size="sm"
                                  v-model.number="item.remainNumber"
                                  type="number"
                                  @input="onRemainNumberInput(tableItem, item)"
                                />
                              </td>
                            </template>
                          </tr>
                          <tr>
                            <th class="bg-light text-dark">{{ $t("optionInventoryEditPage.isSales")}}</th>
                            <template v-for="item in tableItem.inventories">
                              <td :key="item.date">
                                <CSwitch
                                  class="mt-1 swc sw-2"
                                  color="info"
                                  variant="opposite"
                                  :labelOn='$t("optionInventoryEditPage.sales")'
                                  :labelOff='$t("optionInventoryEditPage.stop")'
                                  :checked="item.isSales"
                                  @change="onStatusChanged()"
                                />
                              </td>
                            </template>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <!-- #endregion Inventory -->

                  </template>
                </CCol>
              </CRow>
              <!-- #endregion Table -->

            </CForm>
          </CCardBody>
        </CCard>

      </CCol>
    </CRow>

    <!-- #region Modal -->

    <BulkChangeModal
      :bulkChangeModal="bulkChangeModal"
      :key="bulkChangeModal"
      :tableItems="bulkDatas"
      @close="bulkChangeModal = $event"
      @bulkChangeByProvisionNumber="bulkChangeByProvisionNumber($event)"
      @bulkChangeByRemainNumber="bulkChangeByRemainNumber($event)"
      @bulkChangeByIsSales="bulkChangeByIsSales($event)"
    />

    <ResetChangeModal
      :resetChangeModal="resetChangeModal"
      :callback="reset"
      @close="resetChangeModal = $event"
    />

    <!-- #endregion Modal -->

  </div>
</template>

<script>
// import i18n from "@/i18n";
import BulkChangeModal from "./components/BulkChangeModal";
import ResetChangeModal from "./components/ResetChangeModal";

import {
  required,
  numeric,
  minValue,
  // minLength,
  // maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  components: {
    BulkChangeModal,
    ResetChangeModal,
  },

  validations() {
    return {
      tableItems: {
        $each: {
          inventories: {
            $each: {
              provisionNumber: { required, numeric, minValue: minValue(0) },
              remainNumber: { required, numeric, minValue: minValue(0) },
              excessStandardInventoryNumber: { minValue: minValue(0) },
            },
          },
        },
      },
    };
  },

  data() {
    return {
      //#region Flag
      bulkChangeModal: false,
      resetChangeModal: false,
      //#endregion Flag

      // tableItems: [],
      period: {
        startDate: this.today(),
        endDate: this.dayBeforeOneYearLater(),
      },

      // 開発用サンプルデータ
      tableItems: [],

      // #region 開発用サンプルデータ
      // tableItems: [
      //   {
      //     id: "1",
      //     code: "wa01",
      //     name: "ペット可和室6畳",
      //     standardInventory: 5,
      //     bulkData: {
      //       isUpdate: true,
      //       provisionNumber: 0,
      //       remainNumber: 0,
      //       isSales: true,
      //     },
      //     inventories: [
      //       {
      //         date: "2021/05/19",
      //         provisionNumber: 5,
      //         reservedNumber: 2,
      //         remainNumber: 3,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/20",
      //         provisionNumber: 4,
      //         reservedNumber: 0,
      //         remainNumber: 4,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/21",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/22",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/23",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/24",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/25",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/26",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/27",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/28",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/29",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/30",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/31",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/06/01",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/06/02",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/06/03",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/06/04",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //     ],
      //   },
      //   {
      //     id: "2",
      //     code: "wa02",
      //     name: "ペット可和室10畳",
      //     standardInventory: 2,
      //     bulkData: {
      //       isUpdate: true,
      //       provisionNumber: 0,
      //       remainNumber: 0,
      //       isSales: true,
      //     },
      //     inventories: [
      //       {
      //         date: "2021/05/19",
      //         provisionNumber: 2,
      //         reservedNumber: 2,
      //         remainNumber: 0,
      //         isSales: false,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/20",
      //         provisionNumber: 2,
      //         reservedNumber: 1,
      //         remainNumber: 1,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/21",
      //         provisionNumber: 1,
      //         reservedNumber: 1,
      //         remainNumber: 0,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/22",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/23",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/24",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/25",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/26",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/27",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/28",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/29",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/30",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/05/31",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/06/01",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/06/02",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/06/03",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //       {
      //         date: "2021/06/04",
      //         provisionNumber: 3,
      //         reservedNumber: 1,
      //         remainNumber: 2,
      //         isSales: true,
      //         excessStandardInventoryNumber: 0,
      //       },
      //     ],
      //   },
      // ],
      // #endregion 開発用サンプルデータ

      bulkDatas: [],

      response: [
        {
          id: "1",
          code: "wa01",
          name: "ペット可和室6畳",
          standardInventory: 5,
          bulkData: {
            isUpdate: true,
            provisionNumber: 0,
            remainNumber: 0,
            isSales: true,
          },
          inventories: [
            {
              date: "2021/05/19",
              provisionNumber: 5,
              reservedNumber: 2,
              remainNumber: 3,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/20",
              provisionNumber: 4,
              reservedNumber: 0,
              remainNumber: 4,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/21",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/22",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/23",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/24",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/25",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/26",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/27",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/28",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/29",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/30",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/31",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/06/01",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/06/02",
              provisionNumber: 3,
              reservedNumber: 2,
              remainNumber: 1,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/06/03",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/06/04",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
          ],
        },
        {
          id: "2",
          code: "wa02",
          name: "ペット可和室10畳",
          standardInventory: 2,
          bulkData: {
            isUpdate: true,
            provisionNumber: 0,
            remainNumber: 0,
            isSales: true,
          },
          inventories: [
            {
              date: "2021/05/19",
              provisionNumber: 2,
              reservedNumber: 2,
              remainNumber: 0,
              isSales: false,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/20",
              provisionNumber: 2,
              reservedNumber: 1,
              remainNumber: 1,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/21",
              provisionNumber: 1,
              reservedNumber: 1,
              remainNumber: 0,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/22",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/23",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/24",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/25",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/26",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/27",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/28",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/29",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/30",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/05/31",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/06/01",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/06/02",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/06/03",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
            {
              date: "2021/06/04",
              provisionNumber: 3,
              reservedNumber: 1,
              remainNumber: 2,
              isSales: true,
              excessStandardInventoryNumber: 0,
            },
          ],
        },
      ],

    };
  },

  filters: {
    day(value) {
      return value.substr(5, 5);
    },
  },

  methods: {
    //#region Event

    /** 一括在庫設定ボタン押下 */
    onBulkChangeModal() {
      // TODO: モーダル要データを作る
      this.createBulkDatas();
      this.bulkChangeModal = true;
    },

    /** 一括在庫設定モーダル反映ボタン押下 */
    onBulkChangeInventoryReflectClicked() {
      // TODO: 一括在庫設定
      this.bulkChangeModal = false;
    },

    /** 変更内容リセットボタン押下 */
    onResetChangesClicked() {
      this.resetChangeModal = true;
    },

    /** 変更内容リセットモーダルリセットボタン押下 */
    onResetChangesConfirmResetClicked() {
      this.resetChangeModal = false;
    },

    onSaveClicked() {
      // TODO: 更新処理
      console.log("onSaveClicked()");
    },

    /** 提供数入力 */
    onProvisionNumberInput(tableItem, item) {
      item.remainNumber = item.provisionNumber - item.reservedNumber;
      item.excessStandardInventoryNumber =
        tableItem.standardInventory - item.provisionNumber;
    },

    /** 残個数入力 */
    onRemainNumberInput(tableItem, item) {
      item.provisionNumber = item.remainNumber + item.reservedNumber;
      item.excessStandardInventoryNumber =
        tableItem.standardInventory - item.provisionNumber;
    },

    //#endregion Event

    //#region Request

    reqGet() {
      // TODO: GET処理
      this.tableItems = JSON.parse(JSON.stringify(this.response))
    },

    reqPut() {
      // TODO: PUT処理
    },

    reset() {
      this.reqGet();
      this.resetChangeModal = false;
    },

    //#endregion Request

    //#region Method

    createBulkDatas() {
      this.bulkDatas = [];

      for (let item of this.tableItems) {
        console.log(item);
        const data = {
          id: item.id,
          name: item.name,
          standardInventory: item.standardInventory,
          provisionNumber: 0,
          remainNumber: 0,
          isSales: true,
          isUpdate: false,
          /** 一括在庫設定モーダルで、設定期間内の最大予約数を算出するために使用 */
          inventories: item.inventories.map(a => {
            return {
              date: a.date,
              reservedNumber: a.reservedNumber
            };
          }),
          /** 算出した最大予約数をセット */
          maxReservedNumber: 0,
        };
        this.bulkDatas.push(data);
      }

      // console.log(JSON.stringify(this.bulkDatas));
    },

    /** 本日 */
    today() {
      return new Date();
    },

    /** 1年後の前日 */
    dayBeforeOneYearLater() {
      let date = new Date();
      date.setFullYear(date.getFullYear() + 1);
      date.setDate(date.getDate() - 1);
      return date;
    },

    /** 一括提供数設定 */
    bulkChangeByProvisionNumber(bulkDatas) {
      // 対象のオプションのみ処理する
      // 提供数、残個数、基準在庫数判定値を更新する
      for (let data of bulkDatas) {
        if (!data.isUpdate) continue;
        this.tableItems.forEach(a => {
          if (a.id !== data.id) return;
          a.inventories.forEach(b => {
            b.provisionNumber = data.provisionNumber;
            b.remainNumber = b.provisionNumber - b.reservedNumber;
            b.excessStandardInventoryNumber = a.standardInventory - b.provisionNumber;
          });
        });
      }

    },

    /** 一括残個数設定 */
    bulkChangeByRemainNumber(bulkDatas) {
      // 対象のオプションのみ処理する
      // 提供数、残個数、基準在庫数判定値を更新する
      for (let data of bulkDatas) {
        if (!data.isUpdate) continue;
        this.tableItems.forEach(a => {
          if (a.id !== data.id) return;
          a.inventories.forEach(b => {
            b.remainNumber = data.remainNumber;
            b.provisionNumber = b.remainNumber + b.reservedNumber;
            b.excessStandardInventoryNumber = a.standardInventory - b.provisionNumber;
          });
        });
      }

    },

    /** 一括販売状態設定 */
    bulkChangeByIsSales(bulkDatas) {
      // 対象のオプションのみ処理する
      // 販売状態を更新する
      for (let data of bulkDatas) {
        if (!data.isUpdate) continue;
        this.tableItems.forEach(a => {
          if (a.id !== data.id) return;
          a.inventories.forEach(b => {
            b.isSales = data.isSales;
          });
        });
      }
    },

    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>

<style scoped>
.price-list-date {
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  border: 1px solid #ccc;
}
table {
  border-spacing: 0;
  width: 100%;
  white-space: nowrap;
  border-collapse: collapse;
}
table tr th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 5;
  background-color: #fff;
  width: 100px;
}
/* table {
  width: auto;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-collapse: collapse;
  border-spacing: 0;
}
th,
td {
  display: block;
  width: auto;
  padding: 1em;
  text-align: center;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;
}
thead {
  display: flex;
  float: left;
}
thead th:first-child:before {
  padding: 1em;
}
tbody {
  display: flex;
  width: auto;
  overflow-x: scroll;
}
tbody tr {
  display: flex;
  flex-direction: column;
} */
</style>