const _PROPERTY = {
  GUIDE_ID: 21,
  PARKING_INFO_ID: 22,
  PICKUP_INFO_ID: 23,
};

const _STATUS = {
  VALID: 100,
  INVALID: 200,
};

const _PAGE_STATUS = {
  VISIBLE: "visible",
  DRAFT: "draft",
  INVISIBLE: "invisible",
};

const _HOTEL_STATUS = {
  FLAG_SYSTEM_VALID: 0b00000001,
  FLAG_CONTENT_VISIBLE: 0b00000010,
  FLAG_SEARCHABLE: 0b00000100,
  INVIALID: 0b00000000,
  INVISIBLE: 0b00000001,
  CONTENT_VISIBLE: 0b00000011,
  SEARCHABLE: 0b00000111,
  VISIBLE: 0b11111111,
};

const _CANCEL_POLICY_STATUS = {
  VALID: 1,
  INVALID: 0,
};

const _MODE = {
  CHANGE_PROVISION_NUMBER: "0",
  CHANGE_REMAIN_NUMBER: "1",
  CHANGE_IS_SALES: "2",
};

const _PROPERTYLIST = {
  topics: 1,
  room: 2,
  restaurent: 3,
  equipmentServices: 4,
  basicInformation: 5,
  bathAndHotelTax: 6,
  paymentMethod: 7,
  pets: 8,
  children: 9,
  food: 10,
  roomServices: 11,
  amenity: 12,
  facility: 13,
  others: 14,
  poolInformation: 15,
  massage: 16,
  fitnessGym: 17,
  bedRockBath: 18,
  bath: 19,
  springAndEfficency: 20,
  accessInfo: 21,
  parkingInfo: 22,
  pickupInfo: 23,
  hotelOverview: 24,
  roomSpecification: 25,
  roomSize: 26,
  floorNumber: 27,
  bed: 28,
  recomendation: 29,
  breakfarst: 30,
  dinner: 31,
  lunch: 32,
  sauna: 33,
  oneDayBath: 34,
  hotSpringName: 35,
  bathingTime: 36,
  gymnasium: 37,
  ground: 38,
  dogRun: 39,
  aboutAmenity: 40,
  aboutFeatures: 41,
  checkInTime: 42,
  checkOutTime: 43,
  aboutBath: 44,
  aboutFacility: 45,
  bathAndOnsen: 46,
  totalRooms: 47,
};

const _PLAN_TYPE = {
  PEOPLE_PRICE_STAY: 1,
  ROOM_PRICE_STAY: 2,
  PEOPLE_PRICE_DAY: 3,
  ROOME_PRICE_DAY: 4,
};

const _FEATURE_TYPE = {
  FEATURE: 1,
  AMENITY: 2,
  ROOM_TYPE: 3,
  ROOM_CATEGORY: 4,
  ROOM_FEATURE: 5,
  ROOM_EQUIPMENT: 6,
  SERVICE: 7,
  VIEW: 8,
  FOOD: 9,
  BATH: 10,
  ALLERGEN: 11,
  PLAN_FEATURE: 12,
  OPTION_CATEGORY: 13,
};

const _MASTER_TYPE = {
  /**決済方法 */
  PAYMENT_METHOD: 15,
  /** 宿分類 */

  HOTEL_KIND: 14,
  /** 施設設備・特徴 */

  EQUIPMENT_FEATURE: 1,
  /** サービス・レジャー分類 */
  SERVICE_LEISURE_KIND: 7,
  /** 景観分類 */
  SCENERY_KIND: 8,
  /** お食事 */
  MEAL: 9,
  /** お風呂 */
  BATH: 10,
  /** アレルゲン */

  ALLERGEN: 11,
  /** アメニティ */
  AMENITY: 2,
  /** プラン特徴 */
  PLAN_FEATURE: 12,
  /** 部屋特徴 */
  ROOM_FEATURE: 5,
  /** 部屋設備 */
  ROOM_EQUIPMENT: 6,
  /** 部屋分類 */

  ROOM_KIND: 3,
  /** オプション分類 */

  OPTION_KIND: 13,
};

export default {
  data() {
    return {
      PROPERTY: _PROPERTY,
      STATUS: _STATUS,
      HOTEL_STATUS: _HOTEL_STATUS,
      PAGE_STATUS: _PAGE_STATUS,
      HOTEL_STATUS_LABEL: [
        {
          // 無効
          value: 0b00000000,
          label: this.$t("property.hotelStatusLabel.invalid"),
        },
        {
          // 非表示
          value: 0b00000001,
          label: this.$t("property.hotelStatusLabel.invisible"),
        },
        {
          // 非公開
          value: 0b00000011,
          label: this.$t("property.hotelStatusLabel.contentVisible"),
        },
        {
          // 公開
          value: 0b00000111,
          label: this.$t("property.hotelStatusLabel.searchable"),
        },
        {
          // 公開
          value: 0b11111111,
          label: this.$t("property.hotelStatusLabel.visible"),
        },
      ],
      CANCEL_POLICY_STATUS: _CANCEL_POLICY_STATUS,
      MODE: _MODE,
      PROPERTYLIST: _PROPERTYLIST,
      RoomFloorTypeSelection: [
        { value: "tatami", label: this.$t("roomTypeEditPage.tatami") },
        { value: "m2", label: this.$t("roomTypeEditPage.m2") },
      ],
      PLAN_TYPE_SELECTION: [
        { value: 1, label: this.$t("common.peoplePriceStay") }, // 1: 宿泊・人数売り
        { value: 2, label: this.$t("common.roomPriceStay") }, // 2: 宿泊・部屋売り
        { value: 3, label: this.$t("common.peoplePriceDay") }, // 3: 日帰り・人数売り
        { value: 4, label: this.$t("common.roomPriceDay") }, // 4: 日帰り・部屋売り
      ],
      PLAN_TYPE: _PLAN_TYPE,
      FEATURE_TYPE: _FEATURE_TYPE,
      MASTER_TYPE: _MASTER_TYPE,
      FEATURE_TYPE_LABEL: [
        {
          // 14: 宿分類
          value: _MASTER_TYPE.HOTEL_KIND,
          label: this.$t("featureListPage.selectboxLabel.hotelKind"),
        },
        {
          //  1: 施設設備・特徴
          value: _MASTER_TYPE.EQUIPMENT_FEATURE,
          label: this.$t("featureListPage.selectboxLabel.equipmentFeature"),
        },
        {
          //  7: サービス・レジャー分類
          value: _MASTER_TYPE.SERVICE_LEISURE_KIND,
          label: this.$t("featureListPage.selectboxLabel.serviceLeisureKind"),
        },
        {
          //  8: 景観分類
          value: _MASTER_TYPE.SCENERY_KIND,
          label: this.$t("featureListPage.selectboxLabel.sceneryKind"),
        },
        {
          //  9: お食事
          value: _MASTER_TYPE.MEAL,
          label: this.$t("featureListPage.selectboxLabel.meal"),
        },
        {
          // 10: お風呂
          value: _MASTER_TYPE.BATH,
          label: this.$t("featureListPage.selectboxLabel.bath"),
        },
        {
          // 11: アレルゲン
          value: _MASTER_TYPE.ALLERGEN,
          label: this.$t("featureListPage.selectboxLabel.allergen"),
        },
        {
          //  2: アメニティ
          value: _MASTER_TYPE.AMENITY,
          label: this.$t("featureListPage.selectboxLabel.amenity"),
        },
        {
          // 12:  プラン特徴
          value: _MASTER_TYPE.PLAN_FEATURE,
          label: this.$t("featureListPage.selectboxLabel.planFeature"),
        },
        {
          //  5: 部屋特徴
          value: _MASTER_TYPE.ROOM_FEATURE,
          label: this.$t("featureListPage.selectboxLabel.roomFeature"),
        },
        {
          //  6: 部屋設備
          value: _MASTER_TYPE.ROOM_EQUIPMENT,
          label: this.$t("featureListPage.selectboxLabel.roomEquipment"),
        },
        {
          //  3: 部屋分類
          value: _MASTER_TYPE.ROOM_KIND,
          label: this.$t("featureListPage.selectboxLabel.roomKind"),
        },
        {
          // 13: オプション分類
          value: _MASTER_TYPE.OPTION_KIND,
          label: this.$t("featureListPage.selectboxLabel.optionKind"),
        },
        {
          // 15: 決済方法
          value: _MASTER_TYPE.PAYMENT_METHOD,
          label: this.$t("featureListPage.selectboxLabel.paymentMethod"),
        },
      ],

      TAG_TYPE: [
        { value: 1, label: this.$t("tagTypes.facilityType") }, //  1: 宿形態
        { value: 2, label: this.$t("tagTypes.bath") }, //  2: 風呂
        { value: 3, label: this.$t("tagTypes.hotSpring") }, //  3: 温泉
        { value: 4, label: this.$t("tagTypes.hotSpringQuality") }, //  4: 泉質
        { value: 5, label: this.$t("tagTypes.cooking") }, //  5: 料理
        { value: 6, label: this.$t("tagTypes.food") }, //  6: 食材
        { value: 7, label: this.$t("tagTypes.room") }, //  7: 部屋
        { value: 8, label: this.$t("tagTypes.facilitiesEquipment") }, //  8: 施設・設備
        { value: 9, label: this.$t("tagTypes.leisurePurpose") }, //  9: レジャー・目的
        { value: 10, label: this.$t("tagTypes.facilityPerson") }, // 10: 宿の人
        { value: 11, label: this.$t("tagTypes.location") }, // 11: 立地
      ],

      LOCATION_LABEL_LIST: [
        this.$t("common.locationLevel1"), // 地方
        this.$t("common.locationLevel2"), // 都道府県
        this.$t("common.locationLevel3"), // 地域1
        this.$t("common.locationLevel4"), // 地域2
        this.$t("common.locationLevel5"), // 政令指定都市
        this.$t("common.locationLevel6"), // 市区町村
      ],
    };
  },
};
